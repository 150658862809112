<template>
  <b-form>
    <ValidationObserver ref="activityForm">
      <b-row no-gutters cols="1">
        <b-col>
          <ek-input-text
            label="اسم الفعالية"
            name="اسم الفعالية"
            placeholder="ادخل اسم الفعالية"
            :rules="[
              {
                type: 'required',
              },
            ]"
            v-model="activityForm.accountName"
          ></ek-input-text>
          <ek-input-text
            label="اسم المستخدم"
            name="اسم المستخدم"
            placeholder="ادخل اسم المستخدم"
            :rules="[
              {
                type: 'required',
              },
            ]"
            v-model="activityForm.userName"
          ></ek-input-text>
        </b-col>

        <b-col>
          <ek-input-select
            :options="commercialActivities"
            label="النشاط التجاري"
            name="النشاط التجاري"
            placeholder="ادخل النشاط التجاري"
            v-model="activityForm.commerialActivityId"
            :rules="[
              {
                type: 'required',
              },
            ]"
          ></ek-input-select>
        </b-col>
        <b-col>
          <ek-input-text
            label="البريد الإلكتروني "
            name="البريد "
            placeholder="ادخل البريد الإلكتروني "
            v-model="activityForm.email"
            :rules="[
              {
                type: 'required',
                message: 'البريد الإلكتروني  مطلوب',
              },
              {
                type: 'email',
                message: 'البريد الإلكتروني  غير صالح',
              },
            ]"
          ></ek-input-text>
        </b-col>
        <b-col>
          <ek-input-text
            label="كلمة المرور"
            name="password"
            type="password"
            placeholder="ادخل كلمة المرور"
            :rules="[
              {
                type: 'required',
                message: 'كلمة المرور مطلوبة',
              },
            ]"
            v-model="activityForm.password"
          ></ek-input-text>
        </b-col>
        <b-col>
          <ek-input-text
            label="تأكيد المرور"
            name="تأكيد المرور"
            type="password"
            placeholder="ادخل كلمة المرور مجددا"
            v-model="checkPassword"
            :rules="[
              {
                type: 'required',
              },
              { type: 'confirmed:password', message: 'كلمة المرور غير متطابقة' },
            ]"
          ></ek-input-text>
        </b-col>

        <b-col>
          <ek-input-select
            @input="updateCities($event)"
            class="mt-1"
            name="البلد"
            label="البلد"
            placeholder="البلد"
            :options="countries"
            textLabel="langName"
            v-model="activityForm.countryId"
          ></ek-input-select>
        </b-col>

        <b-col>
          <ek-input-select
            v-if="country == 'سوريا'"
            :rules="[{ type: 'required', message: 'المدينة مطلوبة' }]"
            class="mt-1"
            name="المدينة"
            label="المدينة"
            placeholder="المدينة"
            :options="cities"
            textLabel="langName"
            v-model="activityForm.cityId"
          ></ek-input-select>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-form>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    checkPassword: "",
    useEmail: false,
    country: "",
  }),

  methods: {
    ...mapActions(["addActivities", "getCommercialActivities"]),
    submit() {
      this.$refs.activityForm.validate().then((suc) => {
        if (suc) {
          this.addActivities({
            ...this.activityForm,
            accountVerification: this.activityForm.accountVerification.replace(" ", ""),
          });
          this.reset();
          this.$emit("submited", suc);
        }
      });
    },
    reset() {
      this.country = "";
      this.$refs["activityForm"].reset();
    },
    updateCities($event) {
      this.country = this.countries.find((item) => item.id == $event)?.langName;

      this.activityForm.cityId = null;
    },
  },
  computed: {
    ...mapState({
      commercialActivities: ({ activities }) => activities.commercialActivities,
      activityForm: ({ activities }) => activities.activityForm,
      cities: ({ locations }) => locations.cities,
      countries: ({ locations }) => locations.countries,
    }),
  },
  created() {
    this.getCommercialActivities();
  },
  watch: {
    useEmail() {
      this.$refs["activityForm"].reset();
      this.activityForm.accountVerification = "";
    },
  },
};
</script>
