<template>
  <div>
    <ek-dialog
      title="إضافة فعالية"
      placeholder="البحث عن فعالية"
      btnText="إضافة فعالية"
      ref="activityDialog"
      @ok="addActivities"
      @search="search"
      @close="resetForm"
    >
      <template #body>
        <activity-form
          @submited="$refs.activityDialog.close()"
          ref="activityForm"
        ></activity-form>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import ActivityForm from "./ActivityForm.vue";
export default {
  components: {
    ActivityForm,
  },
  methods: {
    addActivities() {
      this.$refs.activityForm.submit();
    },
    resetForm() {
      this.$refs.activityForm.reset();
      this.$store.commit("Reset_Activity_Form");
    },
    search(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["accountName", "commerialActivityName"],
        query,
      });
    },
  },
};
</script>
